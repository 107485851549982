function linkForm() {

	const form = document.querySelector('[data-js="link-form"]');

	if (form) {
		form.addEventListener('submit', function(e) {
			e.preventDefault();

			const select = form.querySelector('select');
			const value = select.value;

			if (value) {
				window.location.href = value;
			}

		});
	}

}

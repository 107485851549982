function tabs() {

  const tabs = jQuery('[data-js="tabs"]');

  if (tabs) {

    // Tab Toggle
    jQuery('[data-js="tabs-toggle"]').each(function() {

    	var index = jQuery(this).data('index');
    	var toggleText = jQuery(this).text();

      jQuery(this).click(function(e) {

        let thisTab = jQuery(this).closest('[data-js="tabs"]').find('[data-tabs-tab][data-index="'+ index +'"]');

        jQuery(this).closest('[data-js="tabs"]').find('[data-js="tabs-toggle"]').removeClass('is-active');
        jQuery(this).addClass('is-active');
        jQuery(thisTab).addClass('is-active').siblings().removeClass('is-active');

        e.preventDefault();
      });

      // if coming from a unique hash url
      var hash = window.location.hash; 
      if ( jQuery(this).attr('href') == hash) {
        jQuery(this).closest('[data-js="tabs"]').find('[data-js="tabs-toggle"]').removeClass('is-active');
        jQuery(this).addClass('is-active');
        jQuery(this).closest('[data-js="tabs"]').find('[data-tabs-tab][data-index="'+ index +'"]').addClass('is-active').siblings().removeClass('is-active');
      }    

    });

    // Tab Links
    const tabLinks = jQuery('[data-js="tabs-links"]');
    if (tabLinks) {
      jQuery(tabLinks).find('a').click(function(e) {
        var href = jQuery(this).attr('href');
        if ( href.startsWith('#') ) {
          e.preventDefault();
          var matchingTab = jQuery(this).closest('[data-js="tabs"]').find(href);
          if (matchingTab) {
            var index = jQuery(matchingTab).data('index');
            jQuery(this).closest('[data-js="tabs"]').find('[data-js="tabs-toggle"]').removeClass('is-active');
            jQuery(this).closest('[data-js="tabs"]').find('[data-js="tabs-toggle"][data-index="'+ index +'"]').addClass('is-active')
            jQuery(matchingTab).addClass('is-active').siblings().removeClass('is-active');
          }
        }
      });
    }
  }
}

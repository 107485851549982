if( !window.acf ) {
    jQuery( document ).ready(function() {
    	navigation();
        popups(); 
        sliders();
        scrollbarWidth();
        handleScroll();
        accordion();
        accordionSingle();
        tabs();
        facetScroll();
        facetSelect();
        linkForm();
        animateNumbers();
        resizeIframe();
        messageBar();
    });

    jQuery( window ).resize(function() {
        scrollbarWidth();
    });

    window.onpageshow = function(event) {
        if (event.persisted) {
            resetNavigation();
        }
    };
}

// Initialize dynamic block preview (editor).
if( window.acf ) {
    window.acf.addAction( 'render_block_preview/type=accordion', accordion );
    window.acf.addAction( 'render_block_preview/type=accordion', accordionSingle );
    window.acf.addAction( 'render_block_preview/type=tabs', tabs );
    window.acf.addAction( 'render_block_preview/type=icon-tabs', tabs );
    window.acf.addAction( 'render_block_preview/type=icon-list', sliders );
    window.acf.addAction( 'render_block_preview/type=testimonials', sliders );
    window.acf.addAction( 'render_block_preview/type=column-slider', sliders );
    window.acf.addAction( 'render_block_preview/type=card-slider', sliders );
    window.acf.addAction( 'render_block_preview/type=phone-slider', sliders );
    window.acf.addAction( 'render_block_preview/type=history-slider', sliders );
}
function messageBar() {
	
	var prefix = jQuery('#header-message-bar').data('prefix');
	var reapper = jQuery('#header-message-bar').data('reapper');  
	var myCookie = readCookie('hide-message-bar-' + prefix);
	
	if (myCookie) { 
		jQuery('#header-message-bar').css({'display':'none'});	
	} else {
		jQuery('#header-message-bar').css({'display':'block', 'visibility': 'visible'});
		
		let hmb_height = jQuery('#header-message-bar').outerHeight();
		jQuery('body').css({'padding-top':hmb_height});
		jQuery('#masthead').css({'top':hmb_height});
		
		let width = jQuery(window).width();
	    jQuery(window).resize(function(){
	        let hmb_height = jQuery('#header-message-bar').outerHeight();
			jQuery('body').css({'padding-top':hmb_height});
			jQuery('#masthead').css({'top':hmb_height});
	    });
		
	}	
	
	jQuery( "#header-message-bar .close" ).click(function() {
		 jQuery('#header-message-bar').addClass('hide');
		 createCookie('hide-message-bar-'+prefix, '1', reapper);
		 jQuery('body').css({'padding-top': 0});
		 jQuery('#masthead').css({'top': 0});
	});
}

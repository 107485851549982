function facetScroll() {

	(function($) {
      $(document).on('facetwp-refresh', function() {
        if (FWP.soft_refresh == true) {
          FWP.enable_scroll = true;
        } else {
          FWP.enable_scroll = false;
        }
      });
      $(document).on('facetwp-loaded', function() {
        if (FWP.enable_scroll == true) {

        	const element = document.querySelector('.facetwp-template');
        	element.scrollIntoView();
        }
      });
    })(jQuery);
}

function facetSelect() {

	(function($) {
	    const select = $('[data-js="facet-select"]');

	    if (select) {
	    	//Label Click
		    $('[data-js="facet-label"]').click(function(e) {
		    	e.stopImmediatePropagation();
		        $(this).closest('[data-js="facet-select"]').toggleClass('is-active').find('[data-js="facet-options"]').slideToggle('fast');
		    });

	     	//Facet Load
	     	$(document).on('facetwp-loaded', function() {
		     	$(select).each(function() {
		     		const label = $(this).find('[data-js="facet-label"]');
		     		const labelText = $(label).attr('data-label');
		     		const options = $(this).find('.facetwp-radio');
		     		$(options).each(function() {
		     			if ( $(this).hasClass('checked') ) {
			     			var newLabelText = $(this).find('.facetwp-display-value').text();
			     			if (newLabelText) {
				     			$(label).text(newLabelText);
				     		} else {
				     			$(label).text(labelText);
				     		}
				     	}
		     		});
		     	});
		     });
	    }
	})(jQuery);
}
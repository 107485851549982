function accordion() {

  (function($) {

    const accordion = $('[data-js="accordion"]');

    if (accordion) {

      $('[data-js="accordion-toggle"]').click(function(e) {
        e.stopImmediatePropagation();
        $(this).closest('[data-js="accordion-item"]').toggleClass('is-active').find('[data-js="accordion-desc"]').slideToggle('fast');
      });

    }

  })(jQuery);

}

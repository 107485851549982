function sliders() {
	
  jQuery('.slider').not('.slick-initialized').slick({
		arrows: true,
		dots: false,
		fade: false,
  });
  
  jQuery('[data-js="testimonials-slider"]').each(function () {
		var testimonialsSlider = jQuery(this);

		testimonialsSlider.not('.slick-initialized').slick({
			arrows: true,
			dots: false,
			fade: false,
			appendArrows: testimonialsSlider.parent().find('.slider-controls'),
	  	});
	});

  jQuery('[data-js="history-slider"]').each(function () {
		var historySlider = jQuery(this);

		historySlider.not('.slick-initialized').slick({
			arrows: true,
			dots: false,
			fade: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			rows: 0,
			appendArrows: historySlider.parent().find('[data-js="slider-nav"]'),
			responsive: [
				{
					breakpoint: 1024,
				    settings: {
				        slidesToShow: 2,
				    }
				},
				{
					breakpoint: 768,
				    settings: {
				        slidesToShow: 1,
				    }
				}
			]
	  	});
	});

	jQuery('[data-js="card-slider"]').each(function () {
		var cardSlider = jQuery(this);

		cardSlider.not('.slick-initialized').slick({
			arrows: true,
			dots: false,
			fade: false,
			slidesToShow: 3,
			slidesToScroll: 1,
			rows: 0,
			appendArrows: cardSlider.parent().find('[data-js="slider-nav"]'),
			responsive: [
				{
					breakpoint: 1024,
				    settings: {
				        slidesToShow: 2,
				    }
				},
				{
					breakpoint: 768,
				    settings: {
				        slidesToShow: 1,
				    }
				}
			]
	  	});
	});

	jQuery('[data-js="column-slider"]').each(function () {
		var columnSlider = jQuery(this);
		var slidesToShow = jQuery(columnSlider).data('slides-to-show');

		columnSlider.not('.slick-initialized').slick({
			arrows: true,
			dots: false,
			fade: false,
			slidesToShow: slidesToShow,
			slidesToScroll: 1,
			rows: 0,
			appendArrows: columnSlider.parent().find('[data-js="slider-nav"]'),
			responsive: [
				{
					breakpoint: 1024,
				    settings: {
				        slidesToShow: 2,
				    }
				},
				{
					breakpoint: 768,
				    settings: {
				        slidesToShow: 1,
				    }
				}
			]
	  	});
	});

	jQuery('[data-js="phone-slider"]').not('.slick-initialized').slick({
		arrows: true,
		dots: true,
		fade: true,
		infinite: false,
		rows: 0,
		asNavFor: '[data-js="phone-slider-phone"]'
  	});
  	jQuery('[data-js="phone-slider-phone"]').not('.slick-initialized').slick({
		arrows: false,
		dots: false,
		fade: true,
		infinite: false,
		rows: 0,
  	});
  
}


function navigation() {
	
	//ADA Compliance roles
	jQuery(".menu .sub-menu__list").attr("role","menubar");
	jQuery(".menu li").attr("role","menuitem");
	
 	jQuery( function ( $ ) {
	 	// Focus styles for menus when using keyboard navigation
		// Properly update the ARIA states on focus (keyboard) and mouse over events
		$( '[role="menubar"]' ).on( 'focus.aria  mouseenter.aria', '[aria-haspopup="true"]', function ( ev ) {
			$( ev.currentTarget ).attr( 'aria-expanded', true );
		} );
		
		// Properly update the ARIA states on blur (keyboard) and mouse out events
		$( '[role="menubar"]' ).on( 'blur.aria  mouseleave.aria', '[aria-haspopup="true"]', function ( ev ) {
			$( ev.currentTarget ).attr( 'aria-expanded', false );
		} );
	} );
	
	//Toggle Main Nav
	jQuery('[data-toggle="nav"]').click( function () {
		if ( jQuery('body').hasClass('nav-active') ) {
			jQuery('li.menu-item-has-children').removeClass('active');
		}
	  	jQuery('body').toggleClass('nav-active');
  	});

  	//Toogle Search
  	jQuery('[data-toggle="search"]').click( function () {
	  	jQuery('body').toggleClass('search-active');

	  	if ( $(this).hasClass('search-close') ) {
	  		jQuery('.site-navigation .site-search-toggle').focus();
	  	} else {
	  		setTimeout(function() {
        		jQuery('.site-search .search-field').focus();
      		}, 300);
	  	}
      	//return false;
  	});
	
	//Mobile Nav Expandable Children
	jQuery('[data-toggle="sub-menu"]').click(function(e) {

		const caret = jQuery('.menu > li.menu-item-has-children > a').find('.icon');

		if (caret.css('display') != 'none') {
			e.preventDefault();
			if ( jQuery(this).closest('li.menu-item').hasClass("active") ) { 
				jQuery(this).closest('li.menu-item').removeClass('active').find('> .sub-menu').slideUp('fast');
			} else {
				//jQuery('li.menu-item-has-children.active').removeClass('active');
				jQuery(this).closest('li.menu-item').addClass('active').find('> .sub-menu').slideDown('fast');
				jQuery(this).closest('li.menu-item').siblings().removeClass('active').find('> .sub-menu').slideUp('fast');
			}	
		}
	});
	jQuery('[data-hide="sub-menu"]').click(function(e) {
		e.preventDefault();
		jQuery(this).closest('li.menu-item').removeClass('active');
	});

	//Top Level with Children Hover
	jQuery('.main-navigation .menu > .menu-item-has-children').on('mouseover', function(){
	  jQuery('[data-header]').addClass('is-hover');
	}).on('mouseout', function(){
	  jQuery('[data-header]').removeClass('is-hover');
	});

	//Second Level with Children Hover
	// jQuery('.main-navigation .sub-menu__list > .menu-item-has-children').on('mouseover', function(){
	//   jQuery(this).closest('.sub-menu__list').addClass('is-hover');
	// }).on('mouseout', function(){
	//   jQuery(this).closest('.sub-menu__list').removeClass('is-hover');
	// });

	//Mobile Utility Nav Expandable Children
	jQuery('#utility-menu .menu-item-has-children > a').click(function(e) {

		const caret = jQuery('#utility-menu .menu-item-has-children > a').find('.icon');

		if (caret.css('display') != 'none') {
			e.preventDefault();
			$(this).closest('.menu-item').toggleClass('is-active').find('.sub-menu').slideToggle('fast');
		}
	});

	//Mobile Footer Nav Expandable Children
	jQuery('#footer-menu .menu-item-has-children > a').click(function(e) {

		const footerMenu = jQuery('#footer-menu');

		if (footerMenu.css('display') != 'grid') {
			e.preventDefault();

			if ( jQuery(this).closest('.menu-item').hasClass("is-active") ) { 
				jQuery(this).closest('.menu-item').removeClass('is-active').find('> .sub-menu').slideUp('fast');
			} else {
				jQuery(this).closest('.menu-item').addClass('is-active').find('> .sub-menu').slideDown('fast');
				jQuery(this).closest('.menu-item').siblings().removeClass('is-active').find('> .sub-menu').slideUp('fast');
			}


			//$(this).closest('.menu-item').toggleClass('is-active').find('.sub-menu').slideToggle('fast');
			//$(this).closest('.menu-item').siblings().find('.sub-menu').slideUp('fast');
		}
	});

  
}

function resetNavigation() {
	jQuery('body').removeClass('nav-active search-active');
	jQuery('li.menu-item-has-children').removeClass('active');
}

function handleScroll() {
	const headerEl = document.querySelector('[data-header]');
	const sentinalEl = document.querySelector('[data-sentinal]');

	if (headerEl && sentinalEl) {

		const handler = (entries) => {
		  //console.log(entries);
		  // entries is an array of observed dom nodes
		  // we're only interested in the first one at [0]
		  // because that's our .sentinal node.
		  // Here observe whether or not that node is in the viewport
		  if (!entries[0].isIntersecting) {
		    document.querySelector('body').classList.add('header-is-sticky');
		  } else {
		    document.querySelector('body').classList.remove('header-is-sticky');
		  }
		}

		// create the observer
		const observer = new window.IntersectionObserver(handler);
		// give the observer some dom nodes to keep an eye on
		observer.observe(sentinalEl);
	}
}

function accordionSingle() {

  (function($) {

    const accordion = $('[data-js="accordion-single"]');

    if (accordion) {

      //Open First Item
      $(accordion).each(function() {
        var firstItem = $(this).find('[data-js="accordion-item"][data-index="1"]');
        $(firstItem).find('[data-js="accordion-desc"]').show();
      });


      //Toggle Item
      $('[data-js="accordion-single-toggle"]').click(function() {
        var thisAccordion = $(this).closest('.accordion');
        var allItems = $(thisAccordion).find('[data-js="accordion-item"]');
        var allDescs = $(thisAccordion).find('[data-js="accordion-desc"]');
        var allImages = $(thisAccordion).closest('.accordion').find('.accordion__image');


        $target = $(this).closest('[data-js="accordion-item"]');
        var index = $target.attr('data-index');

        if (!$target.hasClass('is-active')) {
          allDescs.slideUp();
          allItems.removeClass('is-active');
          allImages.removeClass('is-active');
          $target.addClass('is-active').find('[data-js="accordion-desc"]').slideDown();

          $(this).closest('.accordion').find('.accordion__image[data-index="' + index + '"]').addClass('is-active');
        }
      });
    }

  })(jQuery);

}